import React, { Component } from "react";

import PropTypes from "prop-types";

import validate from "validate.js";

import UAIlogo from '../../illustrations/uai-logo.png';
import { withStyles } from '@material-ui/core/styles';

import {
    Button,
    Box,
    Container,
    CssBaseline,
    Grid,
    Link,
    TextField,
    Typography,
} from "@material-ui/core";



import constraints from "../../constraints";
import authentication from "../../services/authentication";


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="/">
                Uai Economizei
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const styles = (theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

const initialState = {
    performingAction: false,
    emailAddress: "",
    password: "",
    errors: null,
};


class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = initialState;
    }


    getSignInButton = () => {
        const { emailAddress, password, performingAction } = this.state;
        const { classes } = this.props;
        if (emailAddress && !password) {
            return (
                <Button
                    color="primary"
                    disabled={!emailAddress || performingAction}
                    variant="contained"
                    fullWidth
                    className={classes.submit}
                    onClick={() => this.sendSignInLinkToEmail()}
                >
                    Entrar apenas com email
                </Button>
            );
        }

        return (
            <Button
                color="primary"
                disabled={!emailAddress || performingAction}
                variant="contained"
                fullWidth
                className={classes.submit}
                onClick={() => this.signIn()}
            >
                Entrar
            </Button>
        );
    };

    resetPassword = () => {
        const { emailAddress } = this.state;

        const errors = validate(
            {
                emailAddress: emailAddress,
            },
            {
                emailAddress: constraints.emailAddress,
            }
        );

        if (errors) {
            this.setState({
                errors: errors,
            });
        } else {
            this.setState(
                {
                    errors: null,
                },
                () => {
                    this.setState(
                        {
                            performingAction: true,
                        },
                        () => {
                            authentication
                                .resetPassword(emailAddress)
                                .then((value) => {
                                    this.props.openSnackbar(
                                        `Redefinição de senha foi enviada para ${emailAddress}`
                                    );
                                })
                                .catch((reason) => {
                                    const code = reason.code;
                                    const message = reason.message;

                                    switch (code) {
                                        case "auth/invalid-email":
                                        case "auth/missing-android-pkg-name":
                                        case "auth/missing-continue-uri":
                                        case "auth/missing-ios-bundle-id":
                                        case "auth/invalid-continue-uri":
                                        case "auth/unauthorized-continue-uri":
                                        case "auth/user-not-found":
                                            this.props.openSnackbar(message);
                                            return;

                                        default:
                                            this.props.openSnackbar(message);
                                            return;
                                    }
                                })
                                .finally(() => {
                                    this.setState({
                                        performingAction: false,
                                    });
                                });
                        }
                    );
                }
            );
        }
    };

    signIn = () => {
        const { emailAddress, password } = this.state;

        const errors = validate(
            {
                emailAddress: emailAddress,
                password: password,
            },
            {
                emailAddress: constraints.emailAddress,
                password: constraints.password,
            }
        );

        if (errors) {
            this.setState({
                errors: errors,
            });
        } else {
            this.setState(
                {
                    performingAction: true,
                    errors: null,
                },
                () => {
                    authentication
                        .signIn(emailAddress, password)
                        .then((user) => {

                            const displayName = user.displayName;
                            const emailAddress = user.email;

                            this.props.openSnackbar(
                                `Logado como ${displayName || emailAddress}`
                            );

                        })
                        .catch((reason) => {
                            const code = reason.code;
                            const message = reason.message;

                            switch (code) {
                                case "auth/invalid-email":
                                case "auth/user-disabled":
                                case "auth/user-not-found":
                                case "auth/wrong-password":
                                    this.props.openSnackbar(message);
                                    return;

                                default:
                                    this.props.openSnackbar(message);
                                    return;
                            }
                        })
                        .finally(() => {
                            this.setState({
                                performingAction: false,
                            });
                        });
                }
            );
        }
    };

    sendSignInLinkToEmail = () => {
        const { emailAddress } = this.state;

        const errors = validate(
            {
                emailAddress: emailAddress,
            },
            {
                emailAddress: constraints.emailAddress,
            }
        );

        if (errors) {
            this.setState({
                errors: errors,
            });

            return;
        }

        this.setState(
            {
                performingAction: true,
                errors: null,
            },
            () => {
                authentication
                    .sendSignInLinkToEmail(emailAddress)
                    .then(() => {

                        this.props.openSnackbar(`Email de login enviado para ${emailAddress}`);

                    })
                    .catch((reason) => {
                        const code = reason.code;
                        const message = reason.message;

                        switch (code) {
                            case "auth/argument-error":
                            case "auth/invalid-email":
                            case "auth/missing-android-pkg-name":
                            case "auth/missing-continue-uri":
                            case "auth/missing-ios-bundle-id":
                            case "auth/invalid-continue-uri":
                            case "auth/unauthorized-continue-uri":
                                this.props.openSnackbar(message);
                                return;

                            default:
                                this.props.openSnackbar(message);
                                return;
                        }
                    })
                    .finally(() => {
                        this.setState({
                            performingAction: false,
                        });
                    });
            }
        );
    };

    signInWithAuthProvider = (providerId) => {
        this.setState(
            {
                performingAction: true,
            },
            () => {
                authentication
                    .signInWithAuthProvider(providerId)
                    .then((user) => {

                        const displayName = user.displayName;
                        const emailAddress = user.email;

                        this.props.openSnackbar(
                            `Cadastrado como ${displayName || emailAddress}`
                        );

                    })
                    .catch((reason) => {
                        const code = reason.code;
                        const message = reason.message;

                        switch (code) {
                            case "auth/account-exists-with-different-credential":
                            case "auth/auth-domain-config-required":
                            case "auth/cancelled-popup-request":
                            case "auth/operation-not-allowed":
                            case "auth/operation-not-supported-in-this-environment":
                            case "auth/popup-blocked":
                            case "auth/popup-closed-by-user":
                            case "auth/unauthorized-domain":
                                this.props.openSnackbar(message);
                                return;

                            default:
                                this.props.openSnackbar(message);
                                return;
                        }
                    })
                    .finally(() => {
                        this.setState({
                            performingAction: false,
                        });
                    });
            }
        );
    };

    handleKeyPress = (event) => {
        const { emailAddress, password } = this.state;

        if (!emailAddress && !password) {
            return;
        }

        const key = event.key;

        if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
            return;
        }

        if (key === "Enter") {
            if (emailAddress && !password) {
                this.sendSignInLinkToEmail();
            } else {
                this.signIn();
            }
        }
    };

    handleEmailAddressChange = (event) => {
        const emailAddress = event.target.value;

        this.setState({
            emailAddress: emailAddress,
        });
    };

    handlePasswordChange = (event) => {
        const password = event.target.value;

        this.setState({
            password: password,
        });
    };

    render() {

        const { classes } = this.props;

        const { performingAction, emailAddress, password, errors } = this.state;

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <img src={UAIlogo} style={{maxHeight:"100px", paddingBottom:"1rem"}} alt="logo UAI-Economizei"/>
                    {/*<Avatar className={classes.avatar}>
                        
                        <LockOutlinedIcon />
                    </Avatar>*/}
                    <Typography component="h1" variant="h5">
                        Login
                    </Typography>
                    <form className={classes.form} noValidate
                        onKeyPress={this.handleKeyPress}
                    >
                        <TextField
                            id="email"
                            margin="normal"
                            autoFocus
                            autoComplete="email"
                            disabled={performingAction}
                            error={!!(errors && errors.emailAddress)}
                            fullWidth
                            helperText={
                                errors && errors.emailAddress
                                    ? errors.emailAddress[0]
                                    : ""
                            }
                            label="Email"
                            placeholder="john@doe.com"
                            required
                            type="email"
                            value={emailAddress}
                            variant="outlined"
                            InputLabelProps={{ required: false }}
                            onChange={this.handleEmailAddressChange}
                        />
                        <TextField
                            id="password"
                            margin="normal"
                            autoComplete="current-password"
                            disabled={performingAction}
                            error={!!(errors && errors.password)}
                            fullWidth
                            helperText={
                                errors && errors.password ? errors.password[0] : ""
                            }
                            label="Senha"
                            placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                            required
                            type="password"
                            value={password}
                            variant="outlined"
                            InputLabelProps={{ required: false }}
                            onChange={this.handlePasswordChange}
                        />
                        {/*}<FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Lembrar"
                        />{*/}
                        {this.getSignInButton()}

                        <Grid container>
                            <Grid item xs>
                                <Link variant="body2" onClick={this.resetPassword}>
                                    Esqueceu sua senha?
                            </Link>
                            </Grid>
                            {/*}<Grid item>
                                <Link href="#" variant="body2">
                                    {"Cadastre-se"}
                                </Link>
                        </Grid>{*/}
                        </Grid>
                    </form>
                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        );

    }

}

LoginPage.propTypes = {
    // Styling
    classes: PropTypes.object.isRequired,

    // Custom Functions
    openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(LoginPage);