import React, { useState, useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";
import { firestore } from "../../../firebase";
import firebase from 'firebase';

import { makeStyles } from '@material-ui/core/styles';

import {
    Container,
    Grid,
    Paper,
    Fab,
    Box,
    TextField,
    Button,
    List,
    Typography,
} from "@material-ui/core";
 
import { Refresh as RefreshIcon } from "@material-ui/icons";

import EmptyState from "../../../components/EmptyState";

import Loader from "../../../components/Loader";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


import { ReactComponent as ErrorIllustration } from "../../../illustrations/error.svg";


import ListSubcategories from "./ListSubcategories";

function Alert(props) {
    return <MuiAlert elevation={6} {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

function UpdateCategories(props) {
    const classes = useStyles();
    const db = firestore.collection('categories');
    const { categoryId } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    //feedback
    const [openSnack, setOpenSnack] = useState(false);
    const [snackText, setSnackText] = useState('');
    const [snackSeverity, setSnackSeverity] = useState("");

    //form inputs
    const [categoryTitle, setCategoryTitle] = useState('')

    const [subcategory, setSubcategory] = useState([]);
    const [subcategoryTitle, setSubcategoryTitle] = useState('');


    //validate
    const [validCat, setValidCat] = useState(true);

    const [valid, setValid] = useState(true);

    //fetch product
    useEffect(() => {
        if (categoryId) {
            return firestore.collection('categories')
                .doc(categoryId)
                .onSnapshot(
                    (snapshot) => {
                        setLoading(false);
                        setCategoryTitle(snapshot.data().title);
                        setSubcategory(snapshot.data().subcategory);
                    },
                    (error) => {
                        setLoading(false);
                        setError(error);
                    }
                );
        } else {
            setLoading(false);
        }
    }, [categoryId]);


    //snackbar
    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    //category handler
    const updateCategories = () => {
        let subcatSlug = subcategoryTitle.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase();
        let categorySlug = categoryTitle.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase();
        db.doc(categoryId).update({
            title: categoryTitle,
            categorySlug: categorySlug,
            subcategory: firebase.firestore.FieldValue.arrayUnion({
                title: subcategoryTitle,
                subcatSlug: subcatSlug
            }),
            updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
        }).then(ref => {
            console.log('Atualizado!');
            setOpenSnack(true);
            setSnackText('Categoria atualizada!');
            setSnackSeverity("success");
        });
    }

    const subcatDeleted = () => {
        setOpenSnack(true);
        setSnackText('Subcategoria deletada!');
        setSnackSeverity("success");
    }

    //form submit
    const handleSubmit = event => {
        event.preventDefault();
        if (categoryTitle) {
            if (subcategoryTitle) {
                updateCategories();
            } else {
                setValid(false)
            }
        }
        else {
            setValidCat(false)
        }
    }


    if (error) {
        return (
            <EmptyState
                image={<ErrorIllustration />}
                title="Couldn’t retrieve product"
                description="Something went wrong when trying to retrieve the requested product"
                button={
                    <Fab
                        variant="extended"
                        color="primary"
                        onClick={() => window.location.reload()}
                    >
                        <Box clone mr={1}>
                            <RefreshIcon />
                        </Box>
                    Retry
                </Fab>
                }
            />
        );
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            Editar - {categoryTitle}
                        </Typography>
                        <form noValidate autoComplete="off">
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        id="category-title"
                                        value={categoryTitle}
                                        onChange={event => (setCategoryTitle(event.target.value, setValid(true)))}
                                        fullWidth
                                        required
                                        error={!validCat}
                                        label="Nome da categoria"
                                        helperText={!validCat ? "Título da categoria é obrigatorio." : ""}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                </Grid>
                                <Grid item md={4} xs={8}>
                                    <Typography variant="subtitle1" color="primary" gutterBottom>Subcategorias:</Typography>
                                    <div style={{ display: 'flex', flexFlow: 'row', alignContent: 'end' }}>
                                        <TextField
                                            label="Subcategoria"
                                            fullWidth
                                            error={!valid}
                                            helperText={!valid ? "Digite o título da subcategoria." : ""}
                                            value={subcategoryTitle}
                                            onChange={event => (setSubcategoryTitle(event.target.value, setValid(true)))} />
                                        <Button variant="contained"
                                            color="primary"
                                            style={{ marginTop: 'auto' }}
                                            onClick={handleSubmit}
                                        >Adicionar</Button>
                                    </div>
                                </Grid>

                                <Grid item md={4} xs={12}>
                                    <List dense={true}>
                                        <ListSubcategories subcategory={subcategory} categoryId={categoryId} subcatDeleted={subcatDeleted} />
                                    </List>
                                </Grid>

                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity={snackSeverity}>
                    {snackText}
                </Alert>
            </Snackbar>
        </Container >
    )
}

export default withRouter(UpdateCategories);