import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import _ from "lodash";

import { Container, Grid, Paper } from "@material-ui/core";

import Chart from "./Chart";
import Deposits from "./Deposits";
import Orders from "./Orders";
import { firestore } from "../../firebase";
import moment from "moment";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 300,
    [theme.breakpoints.up("sm")]: {
      height: 260,
    },
  },
}));

export default function Dashboard() {
  const classes = useStyles();

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [groupedOrders, setGroupedOrders] = useState([]);
  const [totalCEPOrders, setTotalCEPsOrders] = useState(0);
  const [totalLiquido, setTotalLiquido] = useState(0);

  // let chartDate = new Date(((new Date('2021-07-01')).toISOString().substr(0, 7)).toString());
  // let chartDate2 = new Date('2021-07-01').toString();

  const [mes, setMes] = React.useState(
    new Date().toISOString().substr(0, 7).toString()
  );

  let now = new Date();
  let thisMonth = new Date(now.getFullYear(), now.getMonth(), 1)
    .toISOString()
    .substr(0, 7)
    .toString();
  let nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1)
    .toISOString()
    .substr(0, 7)
    .toString();

  const [moreValue, setMoreValue] = useState(thisMonth);
  const [lessValue, setLessValue] = useState(nextMonth);

  const [selectDate, setSelectDate] = React.useState(new Date());

  const handleChange = (event) => {
    let _mes = moment(event.target.value).format("YYYY-MM-DD");

    // setMes(event.target.value);
    // let now = new Date(event.target.value);
    // let thisMonth = new Date(now.getFullYear(), now.getMonth(), 1)
    //   .toISOString()
    //   .substr(0, 7)
    //   .toString();
    // let nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1)
    //   .toISOString()
    //   .substr(0, 7)
    //   .toString();
    // setMoreValue(thisMonth);
    // setLessValue(nextMonth);
  };

  useEffect(() => {
    if (selectDate) {
      let thisMonth = `${moment(selectDate).format("YYYY-MM")}-01`;
      let nextMonth = `${moment(selectDate)
        .add(1, "months")
        .format("YYYY-MM")}-01`;
      console.log(thisMonth);
      console.log(nextMonth);

      setMoreValue(thisMonth);
      setLessValue(nextMonth);
    }
  }, [selectDate]);

  useEffect(() => {
    return firestore
      .collection("orders")
      .orderBy("createdAt", "desc")
      .where("createdAt", ">", new Date(moreValue))
      .where("createdAt", "<", new Date(lessValue))
      .onSnapshot(
        (snapshot) => {
          const listOrders = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setOrders(listOrders);

          // console.log(listOrders)

          var value = _.reduce(
            listOrders,
            function (sum, o) {
              //filtra apenas os concluídos
              if (o.status === 1) {
                return sum + (o.order.totalProducts + o.order.cepValue);
              } else {
                return sum;
              }
            },
            0
          );
          setTotalOrders(value);

          var _cepvalues = _.reduce(
            listOrders,
            function (sum, o) {
              //filtra apenas os concluídos
              if (o.status === 1) {
                return sum + o.order.cepValue;
              } else {
                return sum;
              }
            },
            0
          );
          setTotalCEPsOrders(_cepvalues);

          var _totalLucroLiquido = _.reduce(
            listOrders,
            function (sum, o) {
              if (o.status === 1) {
                let totalProd = 0;

                o.order.cart.products.forEach((item) => {
                  totalProd += item.quantity * item.paidPrice;
                });

                return sum + totalProd;
              } else {
                return sum;
              }
            },
            0
          );

          // console.log(_totalLucroLiquido)
          setTotalLiquido(_totalLucroLiquido);

          var concluidos = _.filter(listOrders, function (o) {
            return o.status === 1;
          });
          var mapped = concluidos.map((item) => {
            return {
              ...item,
              data: item.createdAt.toDate().toISOString().substr(0, 10),
            };
          });
          // var grouped = _.chain(mapped).groupBy('data').map((value, key) => ({ day: key, count: value.length, payload: value })).value()
          var grouped = _.chain(mapped)
            .groupBy("data")
            .map((value, key) => ({
              day: key.substring(8) + "/" + key.substr(5, 2),
              qtd: value.length,
            }))
            .value();
          // console.log(_.sortBy(grouped, 'day'))
          setGroupedOrders(_.sortBy(grouped, "day"));
          // console.log(listOrders[0].createdAt.seconds);
        },
        (error) => {
          console.error(error);
        }
      );
  }, [mes, moreValue, lessValue]);

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={8} lg={9}>
          <Paper className={fixedHeightPaper}>
            <Chart
              orders={orders}
              selectDate={selectDate}
              setSelectDate={setSelectDate}
              groupedOrders={groupedOrders}
              handleChange={handleChange}
              mes={mes}
            />
          </Paper>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={fixedHeightPaper}>
            <Deposits
              totalOrders={totalOrders}
              totalLiquido={totalLiquido}
              totalCEPOrders={totalCEPOrders}
              lessValue={lessValue}
            />
          </Paper>
        </Grid>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Orders orders={orders} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
