import React, { useState, useEffect } from 'react';
import { firestore } from "../../firebase";

import { makeStyles } from '@material-ui/core/styles';
import Loader from "../../components/Loader";
import { Refresh as RefreshIcon } from "@material-ui/icons";
import EmptyState from "../../components/EmptyState";
import { ReactComponent as ErrorIllustration } from "../../illustrations/error.svg";

import {
    Fab,
    Container,
    Box
} from "@material-ui/core";

import OrdersTable from './OrdersTable';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    appBar: {
        position: 'relative',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const db = firestore.collection('orders');

export default function OrdersListPage() {
    const classes = useStyles();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        return db.orderBy("createdAt", "desc").onSnapshot(
            (snapshot) => {
                const listOrders = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                setLoading(false);
                setOrders(listOrders); 
                // console.log(listOrders[0].createdAt.seconds);
            },
            (error) => {
                setLoading(false);
                setError(error);
            }
        );
    }, []);

    if (error) {
        return (
            <EmptyState
                image={<ErrorIllustration />}
                title="Couldn’t retrieve orders"
                description="Something went wrong when trying to retrieve the requested orders"
                button={
                    <Fab
                        variant="extended"
                        color="primary"
                        onClick={() => window.location.reload()}
                    >
                        <Box clone mr={1}>
                            <RefreshIcon />
                        </Box>
                    Retry
                </Fab>
                }
            />
        );
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <Container maxWidth="lg" className={classes.container}>
                <OrdersTable orders={orders} />
            </Container>
        </div>
    );
}
