import React, { useState, useEffect } from 'react';
import { firestore } from "../../../firebase";
import firebase from 'firebase';

import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";

import Loader from "../../../components/Loader";

import { Refresh as RefreshIcon } from "@material-ui/icons";

import EmptyState from "../../../components/EmptyState";

import { ReactComponent as ErrorIllustration } from "../../../illustrations/error.svg";

import { green, red } from '@material-ui/core/colors';

import {
    Fab,
    Container,
    Typography,
    Box,
    Table,
    TableBody,
    TableRow,
    TableCell
} from "@material-ui/core";

import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import BuildIcon from '@material-ui/icons/Build';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline style={{ color: red[500] }} {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    PageView: forwardRef((props, ref) => <BuildIcon style={{ color: green[500] }} {...props} ref={ref} />)

};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
}));

const db = firestore.collection('categories');

let listOfSubcat;

function ListSubcat(rowData) {
    listOfSubcat = rowData.subcategory.map((subcat) => {
        if (rowData.subcategory.length === 1) {
            return (
                <TableRow key="0">
                    <TableCell>Não possui subcategorias </TableCell>
                </TableRow>
            )
        } else {
            if (subcat.title === "") {
                return (
                    <React.Fragment key="0"></React.Fragment>
                )
            } else {
                return (
                    <TableRow key={subcat.title}>
                        <TableCell> {subcat.title} </TableCell>
                    </TableRow>
                )
            }
        }
    })
}



function Categories(props) {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const classes = useStyles();

    useEffect(() => {
        return db.onSnapshot(
            (snapshot) => {
                const listCategories = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                setLoading(false);
                setCategories(listCategories);
            },
            (error) => {
                setLoading(false);
                setError(error);
            }
        );
    }, []);

    if (error) {
        return (
            <EmptyState
                image={<ErrorIllustration />}
                title="Couldn’t retrieve categories"
                description="Something went wrong when trying to retrieve the requested categories"
                button={
                    <Fab
                        variant="extended"
                        color="primary"
                        onClick={() => window.location.reload()}
                    >
                        <Box clone mr={1}>
                            <RefreshIcon />
                        </Box>
                    Retry
                </Fab>
                }
            />
        );
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <Container maxWidth="lg" className={classes.container}>

                <MaterialTable className={classes.paper}
                    title={<Typography className={classes.title} variant="h6" id="tableTitle" color="primary" gutterBottom>Categorias</Typography>
                    }
                    icons={tableIcons}
                    columns={
                        [{ title: 'Categoria', field: 'title', filtering: false }]
                    }
                    data={categories}
                    localization={{
                        pagination: {
                            labelRowsSelect: 'linhas',
                            labelDisplayedRows: '{count} de {from}-{to}',
                            firstTooltip: 'Primeira página',
                            previousTooltip: 'Página anterior',
                            nextTooltip: 'Próxima página',
                            lastTooltip: 'Última página'
                        },
                        toolbar: {
                            nRowsSelected: '{0} linha(s) selecionada(s)',
                            searchTooltip: 'Pesquisar',
                            searchPlaceholder: 'Pesquisar'
                        },
                        header: {
                            actions: 'Ações'
                        },
                        body: {
                            emptyDataSourceMessage: 'Não há dados para exibir',
                            filterRow: {
                                filterTooltip: 'Filtrar'
                            },
                            addTooltip: 'Cadastrar',
                            deleteTooltip: 'Deletar',
                            editTooltip: 'Editar',
                            editRow: {
                                saveTooltip: 'Salvar',
                                cancelTooltip: 'Cancelar',
                                deleteText: 'Tem certeza que deseja excluir?'
                            }
                        }
                    }}
                    actions={[
                        {
                            icon: tableIcons.PageView,
                            tooltip: 'Editar',
                            onClick: (event, rowData) => props.history.push(`/update/categories/${rowData.id}`)
                        }
                    ]}
                    detailPanel={rowData => {
                        ListSubcat(rowData);

                        return (
                            <Box margin={1}>
                                <Typography variant="h6" gutterBottom component="div" style={{marginLeft:'1rem'}}>
                                    Subcategorias
                                </Typography>
                                <Table size="small">
                                    <TableBody>
                                        {listOfSubcat}
                                    </TableBody>
                                </Table>
                            </Box>
                        )

                    }}
                    editable={{
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                let categorySlug = newData.title.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase();
                                setTimeout(() => {
                                    db.add({
                                        title: newData.title,
                                        categorySlug: categorySlug,
                                        subcategory: [{
                                            title: ''
                                        }],
                                        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
                                        updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
                                    }).then(ref => {
                                        console.log('Cadastrado!');
                                    });

                                    resolve();
                                }, 1000)
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const dataUpdate = [...categories];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData;
                                    let categorySlug = newData.title.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase();

                                    db.doc(newData.id).update({
                                        title: newData.title,
                                        categorySlug: categorySlug,
                                        updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
                                    }).then(ref => {
                                        console.log('Atualizado!');
                                    });

                                    resolve();
                                }, 1000)
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(async () => {
                                    const dataDelete = [...categories];
                                    const index = oldData.tableData.id;
                                    dataDelete.splice(index, 1);

                                    db.doc(oldData.id).delete().then(function () {
                                        console.log("Document successfully deleted!");
                                    }).catch(function (error) {
                                        console.error("Error removing document: ", error);
                                    });

                                    resolve()
                                }, 1000)
                            }),
                    }}
                    options={{
                        filtering: true,
                        actionsColumnIndex: -1,
                        pageSize: 15,
                        pageSizeOptions: [10, 15, 30, 60, 100],
                        emptyRowsWhenPaging: false
                    }}
                />

            </Container>
        </div>
    );
}

export default withRouter(Categories);