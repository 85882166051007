import React from "react";
// import Link from '@material-ui/core/Link';
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Title from "./Title";
// import _ from 'lodash';
import FormatBRL from "../../util/FormatBRL";
import _ from "lodash";
// import OrdersTable from '../Orders/OrdersTable';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

const statusOptions = ["Em Análise", "Concluído", "Cancelado"];
const statusColor = ["default", "primary", "secondary"];

export default function Orders(props) {
  const classes = useStyles();
  const { orders } = props;

  const calculaLucro = (rowOrder) => {
    // console.log(rowOrder)
    var _totalLucroLiquido = _.reduce(
      rowOrder.cart.products,
      function (sum, o) {
        return sum + (o.quantity * o.value - o.quantity * o.paidPrice);
      },
      0
    );

    // console.log(_totalLucroLiquido)

    if (_totalLucroLiquido) {
      return FormatBRL(_totalLucroLiquido);
    } else {
      return FormatBRL(0);
    }

    // let precoLiq = 0
    // if (rowOrder) {
    //   let lucro = rowOrder.cart.products.map((item) => {
    //     return (item.value - item.paidPrice)
    //   })
    //   precoLiq = lucro
    // }
    // return precoLiq
  };

  return (
    <React.Fragment>
      <Title>Pedidos Recentes</Title>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell align="right">Valor</TableCell>
              <TableCell align="right">Frete</TableCell>
              <TableCell align="right">L. Liquido</TableCell>
              <TableCell align="right">Entrega</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((row, key) => (
              <TableRow key={key}>
                <TableCell component="th" scope="row">
                  {row.order.user.firstName}
                </TableCell>
                <TableCell align="right">
                  {row.order.totalProducts.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </TableCell>
                <TableCell align="right">
                  {row.order.retiradaBalcao ? (
                    "R$ 0,00"
                  ) : (
                    <>
                      {row.order.cepValue > 0
                        ? row.order.cepValue.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })
                        : "à combinar"}
                    </>
                  )}
                </TableCell>
                <TableCell align="right">{calculaLucro(row.order)}</TableCell>
                <TableCell align="right">
                  {row.order.retiradaBalcao ? "Retirada balcão" : "Entrega"}
                </TableCell>
                <TableCell align="center">
                  <Chip
                    color={statusColor[row.status]}
                    label={statusOptions[row.status]}
                  />
                </TableCell>
                <TableCell align="center">
                  {row.createdAt.toDate().toLocaleDateString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
