import React from 'react';
import { firestore } from "../../firebase";
import firebase from 'firebase';

import { makeStyles } from '@material-ui/core/styles';
import { blue, red } from '@material-ui/core/colors';

import {
    Container,
    Typography,
} from "@material-ui/core";

import Chip from '@material-ui/core/Chip';

import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Grid from '@material-ui/core/Grid';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ListAltIcon from '@material-ui/icons/ListAlt';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import FormatBRL from '../../util/FormatBRL';
import _ from 'lodash';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline style={{ color: red[500] }}  {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    ViewDetails: forwardRef((props, ref) => <ListAltIcon style={{ color: blue[500] }} {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    appBar: {
        position: 'relative',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    tableResponsive: {
        overflow: 'auto'
    },
    DialogContent: {
        marginTop: '2rem',
    },
    table: {
        minWidth: 340,
    },
    tableCell: {
        paddingRight: 4,
        paddingLeft: 5
    },
    card: {
        marginTop: '0.5rem',
        marginBottom: '0.5rem'
    }
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const db = firestore.collection('orders');

export default function OrdersListPage(props) {
    const classes = useStyles();
    const { orders } = props;
    const statusOptions = ['Em Análise', 'Concluído', 'Cancelado']
    const statusColor = ['default', 'primary', 'secondary']

    const [actutalRow, setActualRow] = React.useState({
        id: '',
        order: {
            user: {
                uid: '',
                email: '',
                firstName: '',
                lastName: '',
                phone: ''
            },
            address: {
                bairro: '',
                cep: '',
                complemento: '',
                logradouro: '',
                numero: '',
                uf: '',
            },
            cart: {
                products: [
                    {
                        id: '',
                        category: {
                            category: '',
                            subcategory: '',
                            id: '',
                            name: ''
                        },
                        productMainImg: {
                            name: '',
                            url: '',
                        },
                        quantity: 0,
                        stock: 0,
                        value: 0
                    }
                ]
            },
            cepValue: 0,
            status: 0,
            totalProducts: 0

        }
    });
    const [openStatusDialog, setOpenStatusDialog] = React.useState(false);
    const [openDetailDialog, setOpenDetailDialog] = React.useState(false);
    const [status, setStatus] = React.useState(0);

    const handleClickStatus = (rowdata) => {
        setActualRow(rowdata);
        handleClickOpenStatusDialog();
    }

    const handleClickDetails = (rowdata) => {
        setActualRow(rowdata);
        console.log(rowdata);
        handleClickOpenDetailDialog();
    }

    const handleChangeStatus = (event) => {
        setStatus(Number(event.target.value));
    };

    const handleClickOpenStatusDialog = () => {
        setOpenStatusDialog(true);
    };

    const handleClickOpenDetailDialog = () => {
        setOpenDetailDialog(true);
    };

    const handleCloseStatusDialog = () => {
        setOpenStatusDialog(false);
    };

    const handleCloseDetailDialog = () => {
        setOpenDetailDialog(false)
    };

    const updateStatus = () => {

        db.doc(actutalRow.id).update({
            status: status,
            updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
        }).then(ref => {
            console.log('Atualizado!');
            handleCloseStatusDialog()
        });
    }

    const calculaLucro = (rowOrder) => {

        console.log(rowOrder)
        var _totalLucroLiquido = _.reduce(rowOrder.cart.products, function (sum, o) {
            return sum + ((o.quantity * o.value) - (o.quantity * o.paidPrice));
        }, 0);

        console.log(_totalLucroLiquido)

        if (_totalLucroLiquido) {
            return FormatBRL(_totalLucroLiquido)
        }
        else {
            return FormatBRL(0)
        }

    }

    return (
        <React.Fragment>


            {orders ? <MaterialTable className={classes.paper} style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
                title={
                    <Typography className={classes.title} variant="h6" id="tableTitle" color="primary" gutterBottom>Pedidos</Typography>
                }
                data={orders}
                icons={tableIcons}
                columns={[
                    { title: 'Nº Pedido', editable: 'never', field: 'id' },
                    { title: 'Usuário', editable: 'never', field: 'order.user.uid', render: rowData => <React.Fragment>{rowData.order.user.firstName} {rowData.order.user.lastName}</React.Fragment> },
                    { title: 'Valor Produtos', editable: 'never', field: 'order.totalProducts', type: 'numeric', render: rowData => <React.Fragment>{rowData.order.totalProducts.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</React.Fragment> },
                    {
                        title: 'Valor Frete', editable: 'never', field: 'order.cepValue', type: 'numeric', render: rowData => <React.Fragment>
                            {rowData.order.retiradaBalcao ? "R$ 0,00" :
                                <>
                                    {rowData.order.cepValue > 0 ? rowData.order.cepValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                        :
                                        'à combinar'}
                                </>
                            }
                        </React.Fragment>
                    },
                    { title: 'L. Liquido', editable: 'never', field: 'order.cart.products', type: 'numeric', render: rowData => <React.Fragment>{calculaLucro(rowData.order)}</React.Fragment> },
                    { title: 'Tipo de entrega', editable: 'never', field: 'order.retiradaBalcao', type: 'numeric', render: rowData => <React.Fragment>{rowData.order.retiradaBalcao ? 'Retirada balcão' : 'Entrega'}</React.Fragment> },
                    { title: 'Status', editable: 'never', field: 'status', render: rowData => <React.Fragment> <Chip color={statusColor[rowData.status]} label={statusOptions[rowData.status]} onClick={() => handleClickStatus(rowData)} /> </React.Fragment> },
                    { title: 'Data', editable: 'never', field: 'createdAt', render: rowData => <React.Fragment>{rowData.createdAt.toDate().toLocaleDateString()}</React.Fragment> },

                ]}
                actions={[
                    {
                        icon: tableIcons.ViewDetails,
                        tooltip: 'Detalhamento',
                        onClick: (event, rowData) => handleClickDetails(rowData)
                    }
                ]}
                localization={{
                    pagination: {
                        labelRowsSelect: 'linhas',
                        labelDisplayedRows: '{count} de {from}-{to}',
                        firstTooltip: 'Primeira página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Próxima página',
                        lastTooltip: 'Última página'
                    },
                    toolbar: {
                        nRowsSelected: '{0} linha(s) selecionada(s)',
                        searchTooltip: 'Pesquisar',
                        searchPlaceholder: 'Pesquisar'
                    },
                    header: {
                        actions: 'Ações'
                    },
                    body: {
                        emptyDataSourceMessage: 'Não há dados para exibir',
                        filterRow: {
                            filterTooltip: 'Filtrar'
                        },
                        addTooltip: 'Cadastrar',
                        deleteTooltip: 'Deletar',
                        editTooltip: 'Editar',
                        editRow: {
                            saveTooltip: 'Salvar',
                            cancelTooltip: 'Cancelar',
                            deleteText: 'Tem certeza que deseja excluir?'
                        }
                    }
                }}
                editable={{
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataDelete = [...orders];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);

                                db.doc(oldData.id).delete().then(function () {
                                    console.log("Document successfully deleted!");
                                }).catch(function (error) {
                                    console.error("Error removing document: ", error);
                                });

                                resolve()
                            }, 1000)
                        }),
                }}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 30,
                    pageSizeOptions: [10, 15, 30, 60, 100],
                    emptyRowsWhenPaging: false
                }}
            />
                :
                <div>loading</div>

            }




            <Dialog disableBackdropClick disableEscapeKeyDown open={openStatusDialog} onClose={handleCloseStatusDialog}>
                <DialogTitle>Alterar status do pedido</DialogTitle>
                <DialogContent>
                    <form >
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-dialog-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-dialog-select-label"
                                id="demo-dialog-select"
                                value={status}
                                onChange={handleChangeStatus}
                                input={<Input />}
                            >
                                <MenuItem value={0}>Em Análise</MenuItem>
                                <MenuItem value={1}>Concluído</MenuItem>
                                <MenuItem value={2}>Cancelado</MenuItem>
                            </Select>
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseStatusDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={updateStatus} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen open={openDetailDialog} onClose={handleCloseDetailDialog} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleCloseDetailDialog} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Detalhamento do pedido {actutalRow.id}
                        </Typography>
                    </Toolbar>
                </AppBar>


                <Container maxWidth="lg">
                    <div className={classes.DialogContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Typography variant='h5' color='primary'>Usuário:</Typography>
                                <Card variant="outlined" className={classes.card} style={{ maxWidth: "450px" }}>
                                    <CardContent className={classes.cardContent}>
                                        <Typography variant="body2" component="p"><b>ID: </b> {actutalRow.order.user.uid}</Typography>
                                        <Typography variant="body2" component="p"><b>Nome: </b> {actutalRow.order.user.firstName} {actutalRow.order.user.lastName}</Typography>
                                        <Typography variant="body2" component="p"><b>Email: </b> {actutalRow.order.user.email}</Typography>
                                        <Typography variant="body2" component="p"><b>Telefone: </b> {actutalRow.order.user.phone && actutalRow.order.user.phone !== '' ? `(${actutalRow.order.user.phone.substring(0, 2)}) ${actutalRow.order.user.phone.slice(2).substring(0, 5)} - ${actutalRow.order.user.phone.slice(2).substring(5, 9)}` : actutalRow.order.user.phone}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Typography variant='h5' color='primary'>Endereço de entrega:</Typography>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <Card variant="outlined" className={classes.card} style={{ maxWidth: "450px" }}>
                                            <CardContent className={classes.cardContent}>
                                                <Typography variant="body2" component="p">
                                                    {actutalRow.order.retiradaBalcao ?
                                                        <Typography variant="h6" component="p">Retirada no balcão</Typography>
                                                        :
                                                        <>
                                                            <b>Rua: </b>{actutalRow.order.address.logradouro}<br />
                                                            <b>Número: </b>{actutalRow.order.address.numero}<br />
                                                            <b>Complemento: </b>{actutalRow.order.address.complemento}<br />
                                                            <b>Bairro:</b> {actutalRow.order.address.bairro}<br />
                                                            <b>Cidade:</b> {actutalRow.order.address.localidade}<br />
                                                            <b>UF:</b> {actutalRow.order.address.uf}<br />
                                                            <b>CEP:</b> {actutalRow.order.address.cep}
                                                        </>
                                                    }

                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h5' color='primary'>Produtos:</Typography>
                                <div className={classes.tableResponsive}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Produto</TableCell>
                                                <TableCell align="right">Quantidade</TableCell>
                                                <TableCell align="right">Preço unitário</TableCell>
                                                <TableCell align="right">Preço original</TableCell>
                                                <TableCell align="right">Total</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {actutalRow.order.cart.products.map((product => (
                                                <TableRow key={product.id}>
                                                    <TableCell component="th" scope="row">
                                                        {product.name}
                                                    </TableCell>
                                                    <TableCell align="right">{product.quantity}</TableCell>
                                                    <TableCell align="right">{(product.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                                    <TableCell align="right">
                                                        {product.paidPrice ?
                                                            (product.paidPrice).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                                            : ''
                                                        }
                                                    </TableCell>
                                                    <TableCell align="right">{(product.quantity * product.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                                </TableRow>
                                            )))}

                                            <TableRow>
                                                <TableCell ><b>Valor do frete:</b> </TableCell>
                                                <TableCell colSpan={4} align="right">
                                                    {actutalRow.order.retiradaBalcao ?
                                                        <React.Fragment>Retirada no balcão</React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            {actutalRow.order.cepValue === 0 ? <u style={{ color: 'red' }}>à combinar</u> : (actutalRow.order.cepValue).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                        </React.Fragment>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignItems="center" style={{ marginTop: 10 }}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Typography variant='h5' color='primary'>Formas de pagamento:</Typography>
                                <Table size="small" aria-label="formas de pagamento" className="pagamento">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" colSpan={3} scope="row"> Dinheiro / Transferência / Pix </TableCell>
                                            <TableCell align="right">{FormatBRL((actutalRow.order.cepValue + actutalRow.order.totalProducts))}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Cartão 1x</TableCell>
                                            <TableCell align="right">{FormatBRL((actutalRow.order.cepValue + actutalRow.order.totalProducts) * 1.041)}</TableCell>
                                            <TableCell component="th" scope="row">Cartão 7x</TableCell>
                                            <TableCell align="right">{FormatBRL((actutalRow.order.cepValue + actutalRow.order.totalProducts) * 1.11 / 7)}</TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Cartão 2x</TableCell>
                                            <TableCell align="right">{FormatBRL((actutalRow.order.cepValue + actutalRow.order.totalProducts) * 1.05 / 2)}</TableCell>
                                            <TableCell component="th" scope="row">Cartão 8x</TableCell>
                                            <TableCell align="right">{FormatBRL((actutalRow.order.cepValue + actutalRow.order.totalProducts) * 1.13 / 8)}</TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Cartão 3x</TableCell>
                                            <TableCell align="right">{FormatBRL((actutalRow.order.cepValue + actutalRow.order.totalProducts) / 3 * 1.05)}</TableCell>
                                            <TableCell component="th" scope="row">Cartão 9x</TableCell>
                                            <TableCell align="right">{FormatBRL((actutalRow.order.cepValue + actutalRow.order.totalProducts) * 1.17 / 9)}</TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Cartão 4x</TableCell>
                                            <TableCell align="right">{FormatBRL((actutalRow.order.cepValue + actutalRow.order.totalProducts) / 4 * 1.06)}</TableCell>
                                            <TableCell component="th" scope="row">Cartão 10x</TableCell>
                                            <TableCell align="right">{FormatBRL((actutalRow.order.cepValue + actutalRow.order.totalProducts) * 1.18 / 10)}</TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Cartão 5x</TableCell>
                                            <TableCell align="right">{FormatBRL((actutalRow.order.cepValue + actutalRow.order.totalProducts) / 5 * 1.1)}</TableCell>
                                            <TableCell component="th" scope="row">Cartão 11x</TableCell>
                                            <TableCell align="right">{FormatBRL((actutalRow.order.cepValue + actutalRow.order.totalProducts) * 1.19 / 11)}</TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Cartão 6x</TableCell>
                                            <TableCell align="right">{FormatBRL((actutalRow.order.cepValue + actutalRow.order.totalProducts) * 1.1 / 6)}</TableCell>
                                            <TableCell component="th" scope="row">Cartão 12x</TableCell>
                                            <TableCell align="right">{FormatBRL((actutalRow.order.cepValue + actutalRow.order.totalProducts) * 1.2 / 12)}</TableCell>

                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Card variant="outlined" className={classes.card} style={{ maxWidth: "450px", margin: 'auto' }}>
                                    <CardContent className={classes.cardContent}>
                                        <div style={{ fontSize: '1.1rem', textAlign: 'center' }}>
                                            <div>
                                                {actutalRow.order.retiradaBalcao ?
                                                    <b>{(actutalRow.order.totalProducts).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} à vista </b>
                                                    :
                                                    <b>{(actutalRow.order.cepValue + actutalRow.order.totalProducts).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} à vista </b>
                                                }
                                            </div>
                                            <div>
                                                {actutalRow.order.retiradaBalcao ?
                                                    <b>Ou {((actutalRow.order.totalProducts) * 1.2).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} em até 12x de {((actutalRow.order.totalProducts) * 1.2 / 12).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} no cartão</b>
                                                    :
                                                    <b>Ou {((actutalRow.order.cepValue + actutalRow.order.totalProducts) * 1.2).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} em até 12x de {((actutalRow.order.cepValue + actutalRow.order.totalProducts) * 1.2 / 12).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} no cartão</b>
                                                }
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </Dialog>

        </React.Fragment>
    );
}
