import React from "react";
import { firestore } from "../../../firebase";
import firebase from 'firebase';

import { red } from '@material-ui/core/colors';

import {
    ListItem,
    ListItemText,
    IconButton,
} from "@material-ui/core";
import DeleteOutline from '@material-ui/icons/DeleteOutline';


export default function ListSubcategories({ subcategory, categoryId, subcatDeleted }) {


    const handlerDeleteSubcat = (subcat) => {
        if (subcat.subcatSlug) {
            firestore.collection('categories').doc(categoryId).update({
                subcategory: firebase.firestore.FieldValue.arrayRemove({ title: subcat.title, subcatSlug: subcat.subcatSlug })
            }).then(ref => {
                console.log(subcat.title)
                subcatDeleted()
            });
        } else {
            firestore.collection('categories').doc(categoryId).update({
                subcategory: firebase.firestore.FieldValue.arrayRemove({ title: subcat.title })
            }).then(ref => {
                console.log(subcat.title)
                subcatDeleted()
            });
        }
    }

    const listSubcat = subcategory.map((subcat) => {
        if (subcat.title === "") {

            return (
                <React.Fragment key="0"></React.Fragment>
            )
        } else {
            return (
                <ListItem key={subcat.title}>
                    <ListItemText primary={subcat.title} />
                    <IconButton onClick={() => handlerDeleteSubcat(subcat)}>
                        <DeleteOutline style={{ color: red[500] }} />
                    </IconButton>
                </ListItem>
            )
        }
    })

    return (
        <>
            {listSubcat}
        </>
    )


}