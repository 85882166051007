import React, { useState, useEffect, useMemo, useRef } from "react";
import { useParams, withRouter } from "react-router-dom";
import { firestore } from "../../../firebase";
import { storage } from "../../../firebase";
import firebase from 'firebase';
import { useDropzone } from 'react-dropzone';

import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

import {
    Container,
    Grid,
    Paper,
    Fab,
    Box,
    TextField,
    Button,
    IconButton,
    InputLabel,
    Typography,
    Input,
    InputAdornment,
    FormGroup,
    FormControlLabel,
    Switch,
    Radio,
    RadioGroup,
    FormLabel
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { Refresh as RefreshIcon } from "@material-ui/icons";

import EmptyState from "../../../components/EmptyState";

import Loader from "../../../components/Loader";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import TinyEditor from '../Components/TinyEditor';

import { ReactComponent as ErrorIllustration } from "../../../illustrations/error.svg";



function Alert(props) {
    return <MuiAlert elevation={6} {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
    position: 'relative'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};


function UpdateProduct(props) {
    const classes = useStyles();
    const db = firestore.collection('products');
    const { productId } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [onUploading, setOnUploading] = useState(false);

    //feedback
    const [openSnack, setOpenSnack] = useState(false);
    const [snackText, setSnackText] = useState('');
    const [snackSeverity, setSnackSeverity] = useState("");

    //form inputs
    const [name, setName] = useState('');
    const [value, setValue] = useState(0);
    const [paidPrice, setPaidPrice] = useState(0)
    const [category, setCategory] = useState({
        title: 'indefinido',
        id: 'id',
        subcategory: [{
            title: ''
        }]
    });
    const [brand, setBrand] = useState('');
    const [stock, setStock] = useState(0);
    const [activeProduct, setActiveProduct] = useState(false);
    const [isNewRadio, setIsNewRadio] = useState('novo');
    const [description, setDescription] = useState("<p></p>");

    //images
    const [files, setFiles] = useState([]);
    const InputMainImgEl = useRef(null)
    const [mainImg, setMainImg] = useState([])
    const [mainImgPreview, setMainImgPreview] = useState('')

    const onSelectImgClick = () => {
        InputMainImgEl.current.click()
    }

    //validate
    const [valid, setValid] = useState(true);
    const [validCategory, setValidCategory] = useState(true);

    //fetch product
    useEffect(() => {
        if (productId) {
            return firestore.collection('products')
                .doc(productId)
                .onSnapshot(
                    (snapshot) => {
                        setLoading(false);
                        setName(snapshot.data().name);
                        setValue(snapshot.data().value);
                        setPaidPrice(snapshot.data().paidPrice ? snapshot.data().paidPrice : 0);
                        setCategory(snapshot.data().category);
                        setDescription(snapshot.data().description);
                        setStock(snapshot.data().stock);
                        setMainImg(snapshot.data().productMainImg);
                        setFiles(snapshot.data().productImages);
                        setIsNewRadio(snapshot.data().isNew);
                        setActiveProduct(snapshot.data().active);
                        setBrand(snapshot.data().brand);
                    },
                    (error) => {
                        setLoading(false);
                        setError(error);
                    }
                );
        } else {
            setLoading(false);
        }
    }, [productId]);


    //fetch categories
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        return firestore.collection('categories').onSnapshot(
            (snapshot) => {
                const listCategories = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                setCategories(listCategories);
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setError(error);
            }
        );
    }, []);


    var listSubcat = []

    const optionsCategories = categories.map((category) => category.subcategory.map((subcatItem) => {
        if (subcatItem.subcatSlug) {
            listSubcat.push({
                category: category.title,
                categorySlug: category.categorySlug,
                subcategory: subcatItem.title,
                subcatSlug: subcatItem.subcatSlug,
                id: category.id,
            })
            return {
                category: category.title,
                subcategory: subcatItem.title
            }
        } else if (subcatItem.title) {
            listSubcat.push({
                category: category.title,
                subcategory: subcatItem.title,
                id: category.id,
            })
            return {
                category: category.title,
                subcategory: subcatItem.title
            }
        }
    }))

    //fetch brands
    const [brands, setBrands] = useState([]);

    useEffect(() => {
        return firestore.collection('brands').onSnapshot(
            (snapshot) => {
                const listbrands = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                setLoading(false);
                setBrands(listbrands);
            },
            (error) => {
                setLoading(false);
                setError(error);
            }
        );
    }, []);
    const brandsProps = {
        options: brands.map((option) => option.name),
    };

    //snackbar
    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    const onUpAlbum = async (productId) => {
        if (files !== '') {
            const storageRef = storage.ref(`images/products/${productId}/`);

            const imageUpPromises = files.map(async file => {
                if (file.preview) {
                    const fileRef = storageRef.child(file.name)
                    await fileRef.put(file)

                    db.doc(productId).update({
                        productImages: firebase.firestore.FieldValue.arrayUnion({
                            name: file.name,
                            url: await fileRef.getDownloadURL(),
                        })
                    }).then(res => {
                        console.log('Added document com ID existente: ', productId);
                    })
                }

            })

            await Promise.all(imageUpPromises).then((values) => {
                console.log(values);
            });
        }
        setOnUploading(false);
        setOpenSnack(true);
        setSnackText('Produto atualizado com sucesso!');
        setSnackSeverity("success")
    }

    //product handler
    const updateProduct = () => {

        setOnUploading(true);
        db.doc(productId).set({
            name: name,
            value: Number(value),
            paidPrice: Number(paidPrice),
            category: category,
            description: description,
            stock: stock,
            isNew: isNewRadio,
            active: activeProduct,
            brand: brand,
            updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
        }, { merge: true }).then(ref => {
            console.log('Atualizado - set!');
            handleUploadImage(productId)

        });
    }

    //form submit
    const handleSubmit = event => {
        event.preventDefault();
        if (category.title !== "indefinido" && name !== "") {
            updateProduct();
        }
        else {
            if (name === "") {
                setValid(false)
            }
            if (category.title === "indefinido") {
                setValidCategory(false)
            }
        }
    }

    //tiny set content
    const handleEditorChange = (content, editor) => {
        setDescription(content);
        //console.log('Content was updated:', description);
    }

    //image principal
    const [newMainImg, setNewMainImg] = useState('');
    const handleProductImage = e => {
        if (e.target.files[0]) {
            setNewMainImg(e.target.files[0]);
            setMainImgPreview(URL.createObjectURL(e.target.files[0]))
        }
    };
    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        URL.revokeObjectURL(mainImgPreview);
    }, [mainImgPreview]);

    //image upload
    const handleUploadImage = async (productId) => {
        if (newMainImg !== '') {
            const storageRef = storage.ref(`images/products/${productId}/`);
            const fileRef = storageRef.child(newMainImg.name)
            await fileRef.put(newMainImg);

            const oldFileRef = storageRef.child(mainImg.name)
            oldFileRef.delete().then(function () {
                console.log('img antiga deletada com sucesso!')
            }).catch(function (error) {
                console.log(error)
            });

            db.doc(productId).update({
                productMainImg: {
                    name: newMainImg.name,
                    url: await fileRef.getDownloadURL()
                }
            }).then(res => {
                console.log('img principal cadastrada')
            })
        }
        onUpAlbum(productId);
    }

    //active switch
    const handleActive = (event) => {
        setActiveProduct(event.target.checked);
        console.log(activeProduct);
    }

    //product state radio
    const handleIsNewRadio = (event) => {
        setIsNewRadio(event.target.value);
        console.log(isNewRadio);
    }


    //album handler
    const { getRootProps, getInputProps, isDragActive,
        isDragAccept, isDragReject } = useDropzone({
            accept: 'image/*',
            onDrop: acceptedFiles => {
                setFiles([...files, ...acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))]);
            }
        });

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    const onDeleteImageAlbum = async (file) => {

        if (file.url) {
            let fieldValue = firebase.firestore.FieldValue
            let prodRef = db.doc(productId);
            await prodRef.update({
                productImages: fieldValue.arrayRemove(file)
            }).then(ref => {
                var storageRef = storage.ref(`images/products/${productId}/`);
                var fileRef = storageRef.child(file.name)

                fileRef.delete().then(function () {
                    //remove do array de fotos
                    setFiles(files.filter(item => item.name !== file.name));

                    setOpenSnack(true);
                    setSnackText('Imagem excluida!');
                    setSnackSeverity("success");
                }).catch(function (error) {
                    console.log(error);
                    setOpenSnack(true);
                    setSnackText('Erro ao excluir, tente novamente!');
                    setSnackSeverity("danger");
                });
            })
        } else {
            setFiles(files.filter(item => item.name !== file.name));
        }
    }

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.url || file.preview} style={img} alt={file.name} />
            </div>
            <div style={{ position: 'absolute', top: 0, right: 0 }}>
                <IconButton style={{ color: red[500] }} aria-label="delete" onClick={() => onDeleteImageAlbum(file)}>
                    <DeleteOutline fontSize="small" color="error" />
                </IconButton>
            </div>
        </div>
    ));

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    //end album handler

    if (error) {
        return (
            <EmptyState
                image={<ErrorIllustration />}
                title="Couldn’t retrieve product"
                description="Something went wrong when trying to retrieve the requested product"
                button={
                    <Fab
                        variant="extended"
                        color="primary"
                        onClick={() => window.location.reload()}
                    >
                        <Box clone mr={1}>
                            <RefreshIcon />
                        </Box>
                    Retry
                </Fab>
                }
            />
        );
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            Editar
                        </Typography>
                        <form noValidate autoComplete="off">
                            <Grid container spacing={3}>
                                <Grid item xs={5}>
                                    <label>Foto principal:</label>
                                    <div>
                                        <img src={mainImgPreview || mainImg.url || "https://via.placeholder.com/200"} alt="imagem produto" style={{ maxWidth: 200, maxHeight: 200 }} />
                                        <div>
                                            <input type='file' ref={InputMainImgEl} onChange={handleProductImage} style={{ display: 'none' }} />
                                            <Button onClick={onSelectImgClick}
                                                variant="contained"
                                                disabled={onUploading ? true : false}
                                                color="primary">
                                                {mainImg.name === '' ? 'Selecionar imagem' : 'Alterar imagem'}
                                            </Button>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={7}>
                                    <label>Galeria do produto:</label>
                                    <div>
                                        <div className="container">
                                            <div {...getRootProps({ style })}>
                                                <input {...getInputProps()} />
                                                <p>Arraste seus arquivos para cá, ou clique para selecionar.</p>
                                            </div>
                                            <aside style={thumbsContainer}>
                                                {thumbs}
                                            </aside>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField id="product-name"
                                        value={name}
                                        onChange={event => (setName(event.target.value, setValid(true)))}
                                        fullWidth
                                        required
                                        error={!valid}
                                        label="Nome do produto"
                                        helperText={!valid ? "Nome é obrigatorio." : ""}
                                    />
                                </Grid>
                                <Grid item lg={2} md={3} xs={12}>
                                    <InputLabel id="product-paid-price-label">Preço Pago</InputLabel>
                                    <Input id="product-paid-price"
                                        value={paidPrice.toFixed(2)}
                                        onChange={event => setPaidPrice(Number(event.target.value))}
                                        type="number"
                                        fullWidth
                                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                    />
                                </Grid>
                                <Grid item lg={2} md={3} xs={12}>
                                    <InputLabel id="product-value-label">Preço Venda</InputLabel>
                                    <Input id="product-value"
                                        value={value.toFixed(2)}
                                        onChange={event => setValue(Number(event.target.value))}
                                        type="number"
                                        fullWidth
                                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                    />
                                </Grid>
                                <Grid item lg={2} md={3} xs={12}>
                                    <TextField value={stock}
                                        onChange={e => setStock(Number(e.target.value))}
                                        type="number"
                                        label="Quantidade em estoque"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item md={3} xs={12}>
                                    <Autocomplete
                                        options={listSubcat.sort((a, b) => -b.category[0].localeCompare(a.category[0]) || -b.category[1].localeCompare(a.category[1]))}
                                        groupBy={(option) => option.category}
                                        getOptionLabel={option => option.subcategory}
                                        id="categories"
                                        size="small"
                                        value={category}
                                        onChange={(event, newValue) => {
                                            setCategory(newValue);
                                            setValidCategory(true);
                                        }}
                                        renderInput={(params) => (<TextField required error={!validCategory} {...params} label="Categorias" variant="standard" helperText={!validCategory ? "Categoria é obrigatorio." : ""} />)}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <Autocomplete
                                        {...brandsProps}
                                        id="brands"
                                        size="small"
                                        value={brand}
                                        onChange={(event, newValue) => {
                                            setBrand(newValue);
                                        }}
                                        renderInput={(params) => (<TextField {...params} label="Marcas" variant="standard" />)}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <FormLabel component="legend">Visivel para o usuário:</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={activeProduct}
                                                    onChange={handleActive}
                                                    color="primary"
                                                />
                                            }
                                            label="Visivel"
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <FormLabel component="legend">Estado do produto:</FormLabel>
                                    <RadioGroup row aria-label="estado-produto" name="estado" value={isNewRadio} onChange={handleIsNewRadio}>
                                        <FormControlLabel value="novo" control={<Radio />} label="Novo" />
                                        <FormControlLabel value="usado" control={<Radio />} label="Usado" />
                                    </RadioGroup>
                                </Grid>

                                <Grid item xs={12}>
                                    <TinyEditor description={description} onDescriptionChange={handleEditorChange} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained"
                                        color="primary"
                                        disabled={onUploading ? true : false}
                                        onClick={handleSubmit}
                                    >Atualizar</Button>
                                </Grid>

                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity={snackSeverity}>
                    {snackText}
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default withRouter(UpdateProduct);