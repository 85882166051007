import React, { useState, useEffect } from "react";
import { firestore, storage } from "../../../firebase";
import firebase from 'firebase';

import { withRouter } from "react-router-dom";

import Loader from "../../../components/Loader";

import { Refresh as RefreshIcon } from "@material-ui/icons";

import EmptyState from "../../../components/EmptyState";

import { ReactComponent as ErrorIllustration } from "../../../illustrations/error.svg";

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { green, red, blue } from '@material-ui/core/colors';

import {
    Fab,
    Container,
    Typography,
    Box,
    Switch
} from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';

import MaterialTable from 'material-table';
import { forwardRef } from 'react';

//icons
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
//import PageviewIcon from '@material-ui/icons/Pageview';
import BuildIcon from '@material-ui/icons/Build';
import LaunchIcon from '@material-ui/icons/Launch';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline style={{ color: red[500] }} {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    PageView: forwardRef((props, ref) => <BuildIcon style={{ color: green[500] }} {...props} ref={ref} />),
    ExternalLink: forwardRef((props, ref) => <LaunchIcon style={{ color: blue[500] }} {...props} ref={ref} />)

};

const useStyles = makeStyles((theme) => ({
    root: {
        '& > .fas': {
            margin: theme.spacing(2),
        },
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6}  {...props} />;
}

const db = firestore.collection('products');

function ProductsListPage(props) {

    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    //feedback
    const [openSnack, setOpenSnack] = useState(false);
    const [snackText, setSnackText] = useState('');
    const [snackSeverity, setSnackSeverity] = useState("");


    const classes = useStyles();

    //snackbar
    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    useEffect(() => {
        return firestore.collection('products').orderBy("createdAt", "desc").onSnapshot(
            (snapshot) => {
                const listProducts = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }))
                setLoading(false);
                setProducts(listProducts);
                valorTotalProdutos(listProducts);
            },
            (error) => {
                setLoading(false);
                setError(error);
            }
        );
    }, []);


    const productsFormated = products.map((prod) => {
        const prodCategoria = prod.category.category;

        return {
            prodCategory: prodCategoria,
            ...prod
        }
    })

    const [totalVal, setTotalVal] = useState(0);
    const [totalCusto, setTotalCusto] = useState(0);
    const valorTotalProdutos = (listProducts) => { 
        const total = listProducts.reduce(
            (prevValue, currentValue) => prevValue + (currentValue.value * currentValue.stock),
            0
        );

        const totalCusto = listProducts.reduce(
            (prevValue, currentValue) => prevValue + (currentValue.paidPrice ? currentValue.paidPrice * currentValue.stock : 0 ),
            0
        );
 
        setTotalVal(total);
        setTotalCusto(totalCusto);
    }

    const onChangeActive = (event, rowData) => {

        if (rowData.active) {
            db.doc(event.target.name).update({
                active: false
            }).then(ref => {
                console.log('ativo-false Atualizado!');
            });
        } else {
            db.doc(event.target.name).update({
                active: true
            }).then(ref => {
                console.log('ativo-true Atualizado!');
            });
        }

        setOpenSnack(true);
        setSnackText(`${rowData.name} está ${rowData.active ? 'invisivel.' : 'visivel.'}`);
        setSnackSeverity("success")
        setTimeout(() => {
            setOpenSnack(false)
        }, (3000))
    }


    const delteImagesProduct = async (index, dataDelete, oldData) => {

        var imageUpPromises;
        var imagemPrincipalRef;
        var delteImgPrincipal;

        var storageRef = storage.ref(`images/products/${oldData.id}/`);

        if (oldData.productImages) {
            imageUpPromises = oldData.productImages.map(async file => {
                var fileRef = storageRef.child(file.name)
                fileRef.delete().then(function () {
                    console.log('imagem deletada')
                }).catch(function (error) {
                    console.log(error);
                });
            })
        } else {
            imageUpPromises = true;
        }
        if (oldData.productMainImg.name) {
            imagemPrincipalRef = storageRef.child(oldData.productMainImg.name)

            delteImgPrincipal = imagemPrincipalRef.delete().then(function () {
                console.log('imagem principal foi deletada')
            }).catch(function (error) {
                console.log(error)
            });
        } else {
            delteImgPrincipal = true;
        }

        await Promise.all([delteImgPrincipal, imageUpPromises]).then((values) => {
            dataDelete.splice(index, 1);
            console.log('todas as imagens deletadas!');
            db.doc(oldData.id).delete().then(function () {
                console.log("Document successfully deleted!");
                setOpenSnack(true);
                setSnackText('Produto deletado com sucesso!');
                setSnackSeverity("success")
            }).catch(function (error) {
                console.error("Error removing document: ", error);
                setOpenSnack(true);
                setSnackText('Erro ao deletar produto!');
                setSnackSeverity("error")
            });
            setTimeout(() => {
                setOpenSnack(false)
            }, (7000))
        });

    }



    if (error) {
        return (
            <EmptyState
                image={<ErrorIllustration />}
                title="Couldn’t retrieve products"
                description="Something went wrong when trying to retrieve the requested products"
                button={
                    <Fab
                        variant="extended"
                        color="primary"
                        onClick={() => window.location.reload()}
                    >
                        <Box clone mr={1}>
                            <RefreshIcon />
                        </Box>
                        Retry
                    </Fab>
                }
            />
        );
    }


    if (loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <Container maxWidth="lg" className={classes.container}>

                <MaterialTable className={classes.paper}
                    title={
                        <Typography className={classes.title} variant="h6" id="tableTitle" color="primary" gutterBottom>Produtos - <small>custo total: {totalCusto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} - valor total: {totalVal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</small></Typography>
                    }
                    icons={tableIcons}
                    columns={[
                        {
                            title: 'Foto', field: 'productImageUrl', filtering: false, editable: 'never',
                            cellStyle: {
                                textAlign: 'center'
                            },
                            render: rowData => <img loading="lazy" src={rowData.productMainImg.url} style={{ width: 40 }} alt={rowData.productMainImg.name} />
                        },
                        {
                            title: 'Nome', field: 'name', cellStyle: {
                                width: '50%'
                            },
                            render: rowData => <Link variant="body2" href={`https://uaieconomizei.com.br/produtos/${rowData.category.category.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase()}/${rowData.category.subcategory.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase()}/${rowData.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase()}?id=${rowData.id}`} target='_blank'>{rowData.name}</Link>
                        },
                        {
                            title: 'Categorias', field: 'prodCategory', editable: 'never'
                        },
                        { title: 'Estoque', field: 'stock', type: 'numeric' },
                        { title: 'Custo', field: 'paidPrice', type: 'numeric', render: rowData => <React.Fragment>{rowData.paidPrice ? rowData.paidPrice.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}</React.Fragment> },
                        { title: 'Preço', field: 'value', type: 'numeric', render: rowData => <React.Fragment>{rowData.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</React.Fragment> },
                        {
                            title: 'Visivel', field: 'active', filtering: false,
                            render: rowData => <Switch
                                name={rowData.id}
                                checked={rowData.active}
                                onChange={event => onChangeActive(event, rowData)}
                                color="primary" />,
                            editable: 'never',

                        },
                        { title: 'Novo', field: 'isNew', editable: 'never', render: rowData => <React.Fragment><Chip variant="outlined" color={rowData.isNew === "novo" ? "primary" : "secondary"} size="small" label={rowData.isNew} /></React.Fragment> }
                    ]}
                    data={productsFormated}
                    localization={{
                        pagination: {
                            labelRowsSelect: 'linhas',
                            labelDisplayedRows: '{count} de {from}-{to}',
                            firstTooltip: 'Primeira página',
                            previousTooltip: 'Página anterior',
                            nextTooltip: 'Próxima página',
                            lastTooltip: 'Última página'
                        },
                        toolbar: {
                            nRowsSelected: '{0} linha(s) selecionada(s)',
                            searchTooltip: 'Pesquisar',
                            searchPlaceholder: 'Pesquisar',
                            exportTitle: 'Exportar',
                            exportAriaLabel: 'Exportar',
                            exportName: 'Exportar como csv'
                        },
                        header: {
                            actions: 'Ações'
                        },
                        body: {
                            emptyDataSourceMessage: 'Não há dados para exibir',
                            filterRow: {
                                filterTooltip: 'Filtrar'
                            },
                            addTooltip: 'Cadastrar',
                            deleteTooltip: 'Deletar',
                            editTooltip: 'Edição rápida',
                            editRow: {
                                saveTooltip: 'Salvar',
                                cancelTooltip: 'Cancelar',
                                deleteText: 'Tem certeza que deseja excluir?'
                            }
                        }
                    }}
                    actions={[
                        {
                            icon: tableIcons.PageView,
                            tooltip: 'Editar',
                            onClick: (event, rowData) => props.history.push(`/update/product/${rowData.id}`)
                        }
                    ]}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const dataUpdate = [...products];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData;

                                    db.doc(newData.id).set({
                                        name: newData.name,
                                        value: Number(newData.value),
                                        paidPrice: Number(newData.paidPrice),
                                        stock: newData.stock,
                                        updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
                                    }, { merge: true }).then(ref => {
                                        console.log('Atualizado!');
                                    });

                                    resolve();
                                }, 1000)
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(async () => {
                                    const dataDelete = [...products];
                                    const index = oldData.tableData.id;
                                    delteImagesProduct(index, dataDelete, oldData);

                                    resolve()
                                }, 1000)
                            }),
                    }}
                    options={{
                        filtering: true,
                        exportButton: true,
                        actionsColumnIndex: -1,
                        pageSize: 20,
                        pageSizeOptions: [20,50, 100, 150, 200],
                        emptyRowsWhenPaging: false
                    }}
                />

                <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                    <Alert onClose={handleCloseSnack} severity={snackSeverity}>
                        {snackText}
                    </Alert>
                </Snackbar>
            </Container>
        </div>
    );

}
export default withRouter(ProductsListPage);