import React, { useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Title from "./Title";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import brLocale from "date-fns/locale/pt-BR";
import moment from "moment";

// Generate Sales Data
// function createData(time, amount) {
//   return { time, amount };
// }

// const data = [
//   createData('01/06', 4),
//   createData('02/06', 0),
//   createData('03/06', 1),
//   createData('04/06', 2),
//   createData('05/06', 3),
//   createData('06/06', 0),
//   createData('07/06', 4),
//   createData('08/06', 1),
//   createData('09/06', undefined),
// ];

export default function Chart(props) {
  const theme = useTheme();
  const { groupedOrders, selectDate, setSelectDate } = props;
  // var dt = new Date();
  // var month = dt.getMonth();
  // var year = dt.getFullYear();
  // let daysInMonth = new Date(year, month, 0).getDate();

  // useEffect(() => {
  //   console.log(moment(selectDate).format("YYYY-MM"));
  // }, [selectDate]);

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={brLocale}>
        <Grid container justify="space-between">
          <Grid item xs={12} sm={12} md={6}>
            <Title>Gráfico de Orçamentos mensal</Title>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <FormControl style={{ minWidth: 150 }}>
              {/* <InputLabel id="demo-simple-select-label">Periodo</InputLabel> 
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={mes}
                onChange={handleChange}
              >
                <MenuItem value={"2021-07-01"}>Junho</MenuItem>
                <MenuItem value={"2021-08-01"}>Julho</MenuItem>
                <MenuItem value={"2021-09-01"}>Agosto</MenuItem>
                <MenuItem value={"2021-10-01"}>Setembro</MenuItem>
                <MenuItem value={"2021-11-01"}>Outubro</MenuItem>
                <MenuItem value={"2021-12-01"}>Novembro</MenuItem>
                <MenuItem value={"2022-01-01"}>Dezembro</MenuItem>
              </Select>*/}
              <DatePicker
                views={["year", "month"]}
                label={""}
                minDate={new Date("2021-07-01")}
                maxDate={new Date()}
                value={selectDate}
                onChange={(newValue) => {
                  setSelectDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <ResponsiveContainer>
          <LineChart
            data={groupedOrders}
            margin={{
              top: 16,
              right: 16,
              bottom: 0,
              left: 24,
            }}
          >
            <XAxis dataKey="day" stroke={theme.palette.text.secondary} />
            <YAxis stroke={theme.palette.text.secondary}>
              <Label
                angle={270}
                position="left"
                style={{
                  textAnchor: "middle",
                  fill: theme.palette.text.primary,
                }}
              >
                Qtd orçamentos p/ dia
              </Label>
            </YAxis>
            <Tooltip />
            <Line
              type="monotone"
              dataKey="qtd"
              stroke={theme.palette.primary.main}
              dot={true}
            />
          </LineChart>
        </ResponsiveContainer>
      </LocalizationProvider>
    </React.Fragment>
  );
}
