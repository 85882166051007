import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

/*const useStyles = makeStyles((theme) => ({
    root: {
        '& > svg': {
            margin: theme.spacing(2),
        },
    },
}));*/

function UaiIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 53.49 46.05">
                <g id="Camada_1" data-name="Camada 1">
                    <path className="cls-1" d="M138.84,787.17s31.15,0,31.15,0,3.39,5.88,3.35,5.86H128.66l14.07-24.37c0,.06,3.4,5.68,3.39,5.7S138.86,787.13,138.84,787.17Z" transform="translate(-119.85 -747)" />
                    <path className="cls-1" d="M150.22,778.66l-7.4-12.77c-5.27,9.17-10.44,18.18-15.59,27.16h-6.77l22.35-38.93c4.72,8.23,9.34,16.31,14,24.4C156.8,778.51,150.21,778.7,150.22,778.66Z" transform="translate(-119.85 -747)" />
                    <path className="cls-1" d="M169.24,785.88H141.13c1.11-2,2.14-3.78,3.24-5.71H159.2c-5.26-9.26-10.52-18.19-15.67-27.25l3.38-5.92Z" transform="translate(-119.85 -747)" />
                </g>
            </svg>
        </SvgIcon>
    );
}

export default function UaiIconSVG() {
    //const classes = useStyles();

    return (
        <UaiIcon />
    );
}