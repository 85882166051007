import React, { useState } from 'react';
import PropTypes from "prop-types";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    IconButton,
    Divider,
    Menu,
    MenuItem,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse
} from "@material-ui/core";

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ListIcon from '@material-ui/icons/List';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
//import BusinessIcon from '@material-ui/icons/Business';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PanoramaIcon from '@material-ui/icons/Panorama';
import SvgIcon from '@material-ui/core/SvgIcon';


import UserAvatar from "../UserAvatar";

import UaiIconSVG from '../uaiIcon';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

function BoxOpen(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 640 512" >
            <path d="M425.7 256c-16.9 0-32.8-9-41.4-23.4L320 126l-64.2 106.6c-8.7 14.5-24.6 23.5-41.5 23.5-4.5 0-9-.6-13.3-1.9L64 215v178c0 14.7 10 27.5 24.2 31l216.2 54.1c10.2 2.5 20.9 2.5 31 0L551.8 424c14.2-3.6 24.2-16.4 24.2-31V215l-137 39.1c-4.3 1.3-8.8 1.9-13.3 1.9zm212.6-112.2L586.8 41c-3.1-6.2-9.8-9.8-16.7-8.9L320 64l91.7 152.1c3.8 6.3 11.4 9.3 18.5 7.3l197.9-56.5c9.9-2.9 14.7-13.9 10.2-23.1zM53.2 41L1.7 143.8c-4.6 9.2.3 20.2 10.1 23l197.9 56.5c7.1 2 14.7-1 18.5-7.3L320 64 69.8 32.1c-6.9-.8-13.5 2.7-16.6 8.9z" />
        </SvgIcon>
    );
}

export default function MiniDrawer(props) {

    // Properties
    const user = props.user;
    const userData = props.userData;
    const performingAction = props.performingAction;
    const roles = props.roles;


    // Events
    const onAboutClick = props.onAboutClick;
    const onSettingsClick = props.onSettingsClick;
    const onSignOutClick = props.onSignOutClick;

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const [openNestList, setOpenNestList] = useState(false);

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };


    const handleClickNestList = () => {
        setOpenNestList(!openNestList);
        setOpen(true);
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setOpenNestList(false);
    };

    const menuAboutClick = () => {
        onAboutClick();
        handleCloseMenu();
    }
    const menuSettingsClick = () => {
        onSettingsClick();
        handleCloseMenu();
    }
    const menuSignOutClick = () => {
        onSignOutClick();
        handleCloseMenu();
    }

    if (user) {
        return (
            <>

                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar>
                        {roles.includes("admin") && (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                className={clsx(classes.menuButton, {
                                    [classes.hide]: open,
                                })}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}

                        <IconButton
                            color="inherit"
                            component={Link}
                            to="/"
                            edge="start">
                            <UaiIconSVG />
                        </IconButton>
                        <Box display="flex" flexGrow={1}>
                            <Typography color="inherit" variant="h6">
                                {process.env.REACT_APP_TITLE}
                            </Typography>
                        </Box>
                        <IconButton
                            color="inherit"
                            disabled={performingAction}
                            onClick={handleClickMenu}

                        >
                            <UserAvatar user={Object.assign(user, userData)} />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                        >
                            <MenuItem onClick={menuAboutClick}>Sobre</MenuItem>
                            <MenuItem component={Link} to={`/user/${user.uid}`} onClick={handleCloseMenu}>Perfil</MenuItem>
                            <MenuItem onClick={menuSettingsClick}>Configurações</MenuItem>
                            <Divider />
                            <MenuItem onClick={menuSignOutClick}>Logout</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
                {roles.includes("admin") && (
                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            }),
                        }}
                    >
                        <div className={classes.toolbar}>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        </div>
                        <Divider />
                        <List component="nav">
                            <ListItem button component={Link} to="/">
                                <ListItemIcon>
                                    <DashboardIcon />
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItem>
                            <ListItem button onClick={handleClickNestList}>
                                <ListItemIcon>
                                    <BoxOpen />
                                </ListItemIcon>
                                <ListItemText primary="Produtos" />
                                {openNestList ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openNestList} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button component={Link} to="/products" className={classes.nested}>
                                        <ListItemIcon>
                                            <ListIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Listagem" />
                                    </ListItem>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItem button component={Link} to="/create/product" className={classes.nested}>
                                        <ListItemIcon>
                                            <AddCircleOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Cadastrar" />
                                    </ListItem>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItem button component={Link} to="/product-categories" className={classes.nested}>
                                        <ListItemIcon>
                                            <LocalOfferIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Categorias" />
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem button component={Link} to="/brands">
                                <ListItemIcon>
                                    <BusinessCenterIcon />
                                </ListItemIcon>
                                <ListItemText primary="Marcas" />
                            </ListItem>
                            <ListItem button component={Link} to="/users">
                                <ListItemIcon>
                                    <AccountBoxIcon />
                                </ListItemIcon>
                                <ListItemText primary="Usuários" />
                            </ListItem>
                            <ListItem button component={Link} to="/orders">
                                <ListItemIcon>
                                    <AssignmentIcon />
                                </ListItemIcon>
                                <ListItemText primary="Pedidos" />
                            </ListItem>
                            <ListItem button component={Link} to="/ceps">
                                <ListItemIcon>
                                    <LocationOnIcon />
                                </ListItemIcon>
                                <ListItemText primary="Cadastro de CEP" />
                            </ListItem>
                            <ListItem button component={Link} to="/banners">
                                <ListItemIcon>
                                    <PanoramaIcon />
                                </ListItemIcon>
                                <ListItemText primary="Banners" />
                            </ListItem>
                            
                        </List>
                    </Drawer>
                )}
            </>
        );
    }
    else {
        return (<React.Fragment></React.Fragment>)
    }
}

MiniDrawer.propTypes = {
    user: PropTypes.object,
};