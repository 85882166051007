import React from "react";

import PropTypes from "prop-types";

import {
  Card, CardHeader

} from "@material-ui/core";

import UserAvatar from "../UserAvatar";


function UserCard(props) {

  const user = props.user;
  const userData = props.userData;

  return (
    <Card>
      <CardHeader
        avatar={
          <UserAvatar user={Object.assign(user, userData)}/>
        }
        title={`${user.firstName} ${user.lastName}`}
        subheader={user.username}
      />
    </Card>
  );
}

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserCard;
