import React, { Component } from "react";

import PropTypes from "prop-types";

import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";

import HomePage from "../HomePage";
import AdminPage from "../AdminPage";
import UserPage from "../UserPage";
import NotFoundPage from "../NotFoundPage";
import LoginPage from "../../views/LoginPage";

import ProductsListPage from "../../views/Products/ProductsListPage";
import CreateProduct from "../../views/Products/Create";
import UpdateProduct from "../../views/Products/Update";

import ProductCategories from "../../views/Products/Categories";
import UpdateCategories from "../../views/Products/Categories/UpdateCategories";

import Brands from "../../views/Brands";
import CEPS from "../../views/Cep";


import UsersListPage from "../../views/Users";

import OrdersListPage from "../../views/Orders";


import { withStyles } from '@material-ui/core/styles';

import EmptyState from "../EmptyState";

import { ReactComponent as CabinIllustration } from "../../illustrations/cabin.svg";
import Banners from "../../views/Banners";


const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    maxWidth: '100%',
    overflowX:'auto',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },
});



class Router extends Component {
  render() {
    // Properties
    const { user, roles, drawer } = this.props;

    // Functions
    const { openSnackbar } = this.props;

    const { classes } = this.props;

    return (
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        <div className={classes.root}>
          {user ? roles.includes("admin") ? (drawer) : ('') : ('')}
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Switch>
              <Route path="/" exact>
                {user ? (
                  roles.includes("admin") ? (
                    <HomePage user={user} openSnackbar={openSnackbar} />
                  ) : (
                      <EmptyState
                        image={<CabinIllustration />}
                        title="Não foi possivel acessar esta página."
                        description="Solicite permissão para acessa-la."
                      />
                    )
                ) : (
                    <LoginPage openSnackbar={openSnackbar} />
                  )}
              </Route>

              <Route path="/admin">
                {user && roles.includes("admin") ? (
                  <AdminPage />
                ) : (
                    <Redirect to="/" />
                  )}
              </Route>

              <Route path="/user/:userId">
                {user && roles.includes("admin") ? (<UserPage />) : (<Redirect to="/" />)}
              </Route>

              <Route path="/products">
                {user && roles.includes("admin") ? (<ProductsListPage />) : (<Redirect to="/" />)}
              </Route>
              <Route path="/create/product">
                {user && roles.includes("admin") ? (<CreateProduct />) : (<Redirect to="/" />)}
              </Route>
              <Route path="/update/product/:productId">
                {user && roles.includes("admin") ? (<UpdateProduct />) : (<Redirect to="/" />)}
              </Route>
              <Route path="/product-categories">
                {user && roles.includes("admin") ? (<ProductCategories />) : (<Redirect to="/" />)}
              </Route>
              <Route path="/update/categories/:categoryId">
                {user && roles.includes("admin") ? (<UpdateCategories />) : (<Redirect to="/" />)}
              </Route>
              <Route path="/brands">
                {user && roles.includes("admin") ? (<Brands />) : (<Redirect to="/" />)}
              </Route>
              <Route path="/users">
                {user && roles.includes("admin") ? (<UsersListPage />) : (<Redirect to="/" />)}
              </Route>
              <Route path="/orders">
                {user && roles.includes("admin") ? (<OrdersListPage />) : (<Redirect to="/" />)}
              </Route>
              <Route path="/ceps">
                {user && roles.includes("admin") ? (<CEPS />) : (<Redirect to="/" />)}
              </Route>
              <Route path="/banners">
                {user && roles.includes("admin") ? (<Banners />) : (<Redirect to="/" />)}
              </Route>
              <Route>
                <NotFoundPage />
              </Route>
            </Switch>
          </main>
        </div>
      </BrowserRouter>
    );
  }
}

Router.propTypes = {
  // Properties
  user: PropTypes.object,
  roles: PropTypes.array.isRequired,
  bar: PropTypes.element,
  drawer: PropTypes.element,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(Router);
