import React from 'react';
import Link from '@material-ui/core/Link';
// import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import FormatBRL from '../../util/FormatBRL';

function preventDefault(event) {
    event.preventDefault();
}

// const useStyles = makeStyles({
//     depositContext: {
//         flex: 1,
//     },
// });

export default function Deposits(props) {
    // const classes = useStyles();
    const { totalOrders, totalCEPOrders, totalLiquido, lessValue } = props;
    let thisMonth = (new Date(lessValue).toLocaleString('pt-br', { month: 'long' }))

    return (
        <React.Fragment>
            <Title>Lucro liquido: {thisMonth.toUpperCase().substr(0, 1)}{thisMonth.substring(1)}</Title>
            <Typography component="p" variant="h4" style={{marginBottom:'auto'}}>
                {totalOrders - totalLiquido < 0 ? FormatBRL(0) : FormatBRL(totalOrders - totalLiquido - totalCEPOrders)}
            </Typography>
            {/* <Typography color="textSecondary" className={classes.depositContext}>
                até {(new Date().toLocaleDateString())}
            </Typography> */}
            <br />
            <div>
                Total frete: <b>{FormatBRL(totalCEPOrders)}</b>
            </div>
            <div>
                Lucro + frete:<b> {totalOrders - totalLiquido < 0 ? FormatBRL(0) : FormatBRL(totalOrders - totalLiquido)}</b>
            </div>
            <div>
                Total orçamentos: <b>{FormatBRL(totalOrders)}</b>
            </div>
            <div>
                Total sem frete: <b>{FormatBRL(totalOrders - totalCEPOrders)}</b>
            </div>
            <div>
                <Link color="primary" style={{ color: 'gray' }} onClick={preventDefault}>
                    Veja o balanço
                </Link>
            </div>
        </React.Fragment>
    );
}