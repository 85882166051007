import { Button, Container, Fab, Grid, Paper, Typography, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import firebase from 'firebase';
import { firestore, storage } from "../../firebase";

import React, { useEffect, useRef, useState } from 'react'
import BackupIcon from '@material-ui/icons/Backup';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        marginBottom: 30,
    },
    imgWrapper: {
        position: 'relative',
        maxWidth: 'fit-content',
        '& .MuiButtonBase-root': {
            position: 'absolute',
            right: 5,
            top: 5,
            backgroundColor: '#dc004e',
            color: '#fff',
            '&:hover': {
                backgroundColor: '#9a0036',
            }
        }
    },
    imgBanner: {
        maxWidth: '100%'
    }
}))

export default function BannersPage() {
    const InputMainImgEl = useRef(null)
    const classes = useStyles();

    const [mainImg, setMainImg] = useState([])
    const [mainImgPreview, setMainImgPreview] = useState('')
    const [onUploading, setOnUploading] = useState(false);

    const [bannersData, setBannersData] = useState([]);
    const [loadingBanners, setLoadingBanners] = useState(true);

    useEffect(() => {
        setLoadingBanners(true)
        return firestore.collection('banners').orderBy("createdAt", "asc").onSnapshot((querySnapshot) => {
            var bannersData = [];
            querySnapshot.forEach((doc) => {
                bannersData.push({ id: doc.id, ...doc.data() })
                setLoadingBanners(false)
            });
            setBannersData(bannersData)
        });
    }, []);


    //main image
    const handleProductImage = e => {
        if (e.target.files[0]) {
            setMainImg(e.target.files[0]);
            setMainImgPreview(URL.createObjectURL(e.target.files[0]))
        }
    };
    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        URL.revokeObjectURL(mainImgPreview);
    }, [mainImgPreview]);

    const onSelectImgClick = () => {
        InputMainImgEl.current.click()
    }

    const handleUploadImage = async (bannerId) => {
        if (mainImgPreview !== '') {
            const storageRef = storage.ref(`images/banners`);
            const fileRef = storageRef.child(mainImg.name)
            await fileRef.put(mainImg);

            firestore.collection('banners').doc(bannerId).update({
                bannerImg: {
                    name: mainImg.name,
                    url: await fileRef.getDownloadURL()
                },
                updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
            }).then(res => {
                console.log('foto cadastrada')
                setOnUploading(false);

                setMainImg([]);
                setMainImgPreview('')
            })
        }
    }


    const addBanner = () => {
        setOnUploading(true);
        firestore.collection('banners').add({
            bannerImg: {
                name: '',
                url: ''
            },
            createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
            updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
        }).then(ref => {
            console.log('Added document with ID: ', ref.id);
            handleUploadImage(ref.id)
        });
    }

    const deleteBanner = (banner) => {
        console.log(banner)
        firestore.collection('banners').doc(banner.id).delete().then(ref => {
            console.log("Document successfully deleted!");

            var storageRef = storage.ref(`images/banners`);
            var fileRef = storageRef.child(banner.bannerImg.name)

            fileRef.delete().then(function () {
                console.log('Imagem excluida!');
            }).catch(function (error) {
                console.error("Error removing image on storage: ", error);
            });

        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
    }


    return (
        <div>
            <Container maxWidth="xl">
                <Paper className={classes.root}>
                    <Typography color="primary" variant="h6">Cadastro de Banners</Typography>
                    <div>
                        <Typography variant="subtitle1">Preview:</Typography>
                        <img src={mainImgPreview ? mainImgPreview : "https://via.placeholder.com/900x350"} alt="banner-1" style={{ maxWidth: '100%', maxHeight: 350 }} />
                        <div>
                            <input type='file' ref={InputMainImgEl} onChange={handleProductImage} style={{ display: 'none' }} />
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Button onClick={onSelectImgClick}
                                        variant="contained"
                                        disabled={onUploading ? true : false}
                                        color="primary">
                                        {mainImgPreview === '' ? 'Selecionar imagem' : 'Alterar imagem'}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={() => addBanner()} disabled={onUploading ? true : false}>
                                        <BackupIcon />
                                        Upload
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Paper>
                <Paper className={classes.root}>
                    <Typography color="primary" variant="h6">Banners Home</Typography>
                    <Typography variant="subtitle1">Gestão de banners da home</Typography>
                    {loadingBanners && bannersData[0] ?
                        '' :
                        <Grid container>
                            {bannersData.map((item, key) => (
                                <Grid item xs={12} key={key}>
                                    <Typography variant="subtitle1">Banner {key + 1}</Typography>
                                    <div className={classes.imgWrapper}>
                                        <img className={classes.imgBanner} src={item.bannerImg.url} alt={item.bannerImg.name} />
                                        <Tooltip title="Deletar" aria-label="delete">
                                            <Fab size="small" color="secondary" aria-label="delete" onClick={() => deleteBanner(item)}>
                                                <DeleteForeverIcon />
                                            </Fab>
                                        </Tooltip>
                                    </div>
                                    
                                </Grid>
                            ))}
                        </Grid>
                    }
                </Paper>
            </Container>
        </div>
    )
}