import React from 'react';
import { Editor } from '@tinymce/tinymce-react';


function TinyEditor(props) {
    const { description, onDescriptionChange } = props;
    return (
        <Editor
            apiKey="lfxahdzhcsrjrepog68p875wkbotvrz2wupfuhyc7dt544i8"
            
            value={description}
            init={{
                height: 500,
                menubar: 'file edit view insert format tools table help',
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor charmap quickbars emoticons',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount image imagetools'
                ],
                image_advtab: true,
                toolbar:
                    'undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent |  removeformat | image | code | help'
            }}
            onEditorChange={onDescriptionChange}
        />
    );
}

export default TinyEditor;
